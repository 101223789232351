import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SecondaryButton from './SecondaryButton';

const CellMap = styled.div`
    display: flex;
    flex-direction: column;
    padding:0;
    margin:8.5% 0%;

    @media (max-width: 767px) {
        width: 100%;
        margin: 10% auto;
    }

`;

const ContentMap = styled.div`
    display: flex;
    flex-direction:column;

    @media (min-width: 1029px) {
        flex-direction:row;
        justify-content: space-between;
    }
`;

const DescriptionMap = styled.div`
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media (max-width: 1024px) {
        width: 100%;
        margin-left:0%;
    }
`;

const TitleMap = styled.h3`
    font-size: 24px;
    font-weight: 300;
    margin:0;

`;

const Description = styled.p`
    font-size: 14px;
    font-weight: 200;
    line-height: 21px;
    
    @media (max-width: 1024px) {
        margin-bottom:0%;
    }
`;


const ScrollCardMobile = styled.div`
    display:flex;
    flex-direction:row;

    @media (max-width: 768px) {
        justify-content: space-between;
        margin:7% 0%;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
          }

    }
`;

const Card = styled.div`
    width:195px;
    height:250px;
    background-color:white;
    border-radius:10px;
    margin:10px;
    box-shadow: 0px 8px 8px #2727274a;
    display:flex;
    flex-direction:column;
    flex: 0 0 auto;
    overflow: hidden;
`;

const BgPicture = styled.div`
    height:60%;
    overflow: hidden;
`;

const Picture = styled.img`
    width:100%;
`;

const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding: 4%;

`;

const TextCard = styled.p`
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    color: #434343;
    text-align:center;
    overflow-wrap: break-word;

`;

const LinkLearnMore = styled.a`
`;

const ColumnApp = styled.div`
    display:flex;
    flex-direction:column;
`;

const AppLink = styled.a`
    margin-right: 5%;
`;

const CellApp = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    flex-wrap:wrap;
    margin:2% 0%;

    @media (min-width: 768px) and (max-width: 1024px) {
        justify-content: space-between;
        width: 50%;
    }
`;

const ImgApp = styled.img`
    width:130px;
`;

const ThreeCardsCustomers = ({
    title,
    descriptionCustomer,
    LinkApp,
    LinkApp2,
    url,
    textButton,
    pictureCard1,
    altPicture1,
    titleCard1,
    pictureCard2,
    altPicture2,
    titleCard2,
    pictureCard3,
    altPicture3,
    titleCard3
}) => (
    <CellMap>
        <ContentMap>
            <DescriptionMap>
                <TitleMap>
                    {title}
                </TitleMap>
                <ColumnApp>
                    <Description>{descriptionCustomer}</Description>
                    <CellApp>
                        <AppLink href={LinkApp}
                            target='_blank'>
                            <ImgApp src='/images/industries/culture/appstore-icon.svg'/>
                        </AppLink>
                        {LinkApp2 ? (
                            <AppLink href={LinkApp2}
                                target='_blank'>
                                <ImgApp src='/images/industries/culture/googleplay-icon.svg'/>
                            </AppLink>
                        ) : null}
                    </CellApp>
                </ColumnApp>
                <LinkLearnMore href={url}>
                    <SecondaryButton titleButton={textButton} />
                </LinkLearnMore>
            </DescriptionMap>
            <ScrollCardMobile>
                <Card>
                    <BgPicture>
                        <Picture src={pictureCard1}
                            alt={altPicture1} />
                    </BgPicture>
                    <TextContent>
                        <TextCard>{titleCard1} </TextCard>
                    </TextContent>
                </Card>
                <Card>
                    <BgPicture>
                        <Picture src={pictureCard2}
                            alt={altPicture2} />
                    </BgPicture>
                    <TextContent>
                        <TextCard>{titleCard2}</TextCard>
                    </TextContent>
                </Card>
                <Card>
                    <BgPicture>
                        <Picture src={pictureCard3}
                            alt={altPicture3} />
                    </BgPicture>
                    <TextContent>
                        <TextCard>{titleCard3}</TextCard>
                    </TextContent>
                </Card>
            </ScrollCardMobile>
        </ContentMap>
    </CellMap>
);

ThreeCardsCustomers.propTypes = {
    title: PropTypes.string,
    descriptionCustomer: PropTypes.string,
    LinkApp: PropTypes.string,
    LinkApp2: PropTypes.string,
    url: PropTypes.string,
    textButton: PropTypes.string,
    pictureCard1: PropTypes.string,
    altPicture1: PropTypes.string,
    titleCard1: PropTypes.string,
    pictureCard2: PropTypes.string,
    altPicture2: PropTypes.string,
    titleCard2: PropTypes.string,
    pictureCard3: PropTypes.string,
    altPicture3: PropTypes.string,
    titleCard3: PropTypes.string
};

export default React.memo(ThreeCardsCustomers);
