import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import ThreeCardsCustomers from '../components/ThreeCardsCustomers';
import HeaderMap from '../components/HeaderMap';
import BackgroundPicture from '../components/BackgroundPicture';
import CardCustomer from '../components/CardCustomer';
import SectionOnlyFooter from '../components/SectionOnlyFooter';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';

const RowIconsCells = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin: 5% 0%;

    @media (min-width: 320px) and (max-width: 767px) {
        flex-direction:column;
    }
`;

const RowIconsDescription = styled.div`
    margin: 15px;
    width: 20%;

    @media (min-width: 320px) and (max-width: 767px) {
        margin: 9px;
        width: 43%;
    }
`;

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const IconContent = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: baseline;

    @media (min-width: 320px) and (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`;

const TextCell = styled.p`
    font-size: 14px;
    font-weight: 200;
`;

const Picture = styled.img`
    max-width: 2vh;
`;

const BgDarkBlue = styled.div`
    position:absolute;
    left:0;
    z-index: -10000;
    background-color: #0c499b;
    width: 100%;
    height: 50%;

    @media (max-width: 768px) {
        height:41%;
    }
`;

const VideoIframe = styled.div`
    position: relative;
    padding-bottom: 53%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border-radius: 10px;
    margin: 7% 0%;
`;

const IframeVideo = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const LivemapIframe = styled.div`
    display: flex;
    width: 100%;
    height: 560px;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    box-shadow: 3px 3px 18px 3px rgba(99,99,99,.16863);
`;

const CardCustomerLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    margin: 7% 0%;


    @media (max-width: 767px) {
        flex-wrap:nowrap;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        touch-action: pan-x;
    }
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('culturePage');

    const icons = [{
        icon: 'icon-location1',
        text: t('TITLE_INTERACTIVE_MAP')
    }, {
        icon: 'icon-map1',
        text: t('TITLE_FESTIVAL_MAP')
    },
    {
        icon: 'icon-map3',
        text: t('TITLE_EXPOS')
    }];

    const museum = [{
        icon: 'icon-map1',
        text: t('TITLE_INTERACTIVE_SCREEN')
    }, {
        icon: 'icon-live_tv',
        text: t('TITLE_WORLD_MAP')
    },
    {
        icon: 'icon-pencil',
        text: t('TITLE_CUSTOMIZED')
    }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_TOURISME')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_TOURISME')}/>
            </Helmet>
            <Layout>
                <BackgroundPicture
                    image={data.background.childImageSharp}
                    alt='personne scanning musuem art and galeries'
                />
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos
                        title={t('TITLE_THEY_USE')}
                        size='large'
                        customersLogos={[
                            {image: '/images/industries/culture/musee_du_louvre_logo.png'},
                            {image: '/images/industries/versailles-logo.png'},
                            {image: '/images/home/logo-ministere-culture.png'},
                            {image: '/images/industries/events/icom.svg'},
                            {image: '/images/industries/culture/inma-logo.png'}
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_PROMOTE')}
                        cellParagraph={t('DESCRIPTION_CUSTOMIZED_MAPS')}
                        pictureRight={data.hachette.childImageSharp}
                        altDescription='mobile with colorful indoor map'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-extension',
                                text: t('TITLE_LAUNCH')
                            },
                            {
                                icon: 'icon-location22',
                                text: t('TITLE_LARGE_SCALE')
                            },
                            {
                                icon: 'icon-laptop_windows',
                                text: t('TITLE_LIMITED')
                            },
                            {
                                icon: 'icon-phone',
                                text: t('TITLE_TREASURE')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_IMPROVE_USER')}
                            text={t('DESCRIPTION_CREATE_FESTIVAL')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            leftIcon='/images/industries/events/feno-logo.png'
                            icons={icons}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_INFORM_USER')}
                            text={t('DESCRIPTION_LOUVRE_MUSEUM')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            leftIcon='/images/industries/culture/musee_du_louvre_logo_white.png'
                            icons={museum}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_TREASURE_HUNT')}
                        cellParagraph={t('DESCRIPTION_ANIMATE')}
                        pictureRight={data.uniqueHeritages.childImageSharp}
                        altDescription='mobile with colorful indoor map'
                        titleButton={t('TITLE_BUTTON_AM_INTERESTED')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                    />

                    <RowIconsCells>
                        <IconContent>
                            <RowIconsDescription>
                                <Picture src='/images/industries/eye-icon.svg'
                                    alt='' />
                                <TextCell>{t('UNIQUE_TECHNO')}</TextCell>
                            </RowIconsDescription>
                            <RowIconsDescription>
                                <Picture src='/images/industries/love-icon.svg'
                                    alt='' />
                                <TextCell>{t('EXPERT_CONTENT')}</TextCell>
                            </RowIconsDescription>
                            <RowIconsDescription className='wemap-container-row-icons-description'>
                                <Picture src='/images/industries/time-icon.svg'
                                    alt='' />
                                <TextCell>{t('TURNKEY_PRODUCT')}</TextCell>
                            </RowIconsDescription>
                            <RowIconsDescription>
                                <Picture src='/images/industries/dollar-icon.svg'
                                    alt='' />
                                <TextCell>{t('COMPLETE_SERVICE')}</TextCell>
                            </RowIconsDescription>
                        </IconContent>
                    </RowIconsCells>

                    <BgDarkBlue></BgDarkBlue>
                    <HeaderMap
                        customerCicleImageUrl='/images/industries/culture/logo_uhm.png'
                        titleCaseStudy={t('PARTNERS')}
                        title={t('ADVENTURES_QH')}
                        by={t('TEXT_BY')}
                        text={t('UHM')}
                        textPointInfos={t('OUTDOOR_INDOOR')}
                        textViewsInfos={t('PUBLIC')}
                        descriptionCustomer={t('UHM_LEADER')}
                        infosRight1={t('OPEN_WORLD')}
                        infosRight2={t('AR')}
                        infosRight3={t('RECOGNITION')}
                        infosRight4={t('QUIZZ')}
                    />

                    <VideoIframe>
                        <IframeVideo title="livemap"
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/7hzv1rRfIiw"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></IframeVideo>
                    </VideoIframe>

                    <ThreeCardsCustomers
                        url='https://www.quellehistoire.com/quelle-histoire-aventures/'
                        title={t('QH_LOUVRE_LENS')}
                        descriptionCustomer={t('GAME_APP')}
                        LinkApp='https://itunes.apple.com/fr/app/louvre-lens-aventure/id1456281606'
                        textApp={t('APP_IOS')}
                        textButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                        pictureCard1='/images/industries/culture/homere-card.png'
                        altPicture1='Illustration of man'
                        pictureCard2='/images/industries/culture/helene-card.png'
                        altPicture2='Illustration of woman'
                        pictureCard3='/images/industries/culture/priam-card.png'
                        altPicture3='Illustration of old man'
                        titleCard1={t('THEME_ODYSSEY')}
                        titleCard2={t('AR_INTEGRATION')}
                        titleCard3={t('APP_EDUTAINMENT')}
                    />

                    <HeaderMap customerCicleImageUrl='/images/industries/events/icon_culture.png'
                        alt='Ministère de la Culture logo'
                        titleCaseStudy={t('Case Study')}
                        title={t('TITLE_MINISTERE_CULTURE')}
                        by={t('TEXT_BY')}
                        text={t('TITLE_DESCRIPTION_MINISTERE_CULTURE')}
                        textPointInfos={t('TITLE_POINTS_MINISTERE_CULTURE')}
                        textViewsInfos={t('TITLE_VIEWS_MINISTERE_CULTURE')}
                        descriptionCustomer={t('TITLE_MINISTERE_CULTURE_DESCRIPTION_MAP')}
                        infosRight1={t('TITLE_DESCRIPTION_RIGHT_MINISTERE_CULTURE_0')}
                        infosRight2={t('TITLE_DESCRIPTION_RIGHT_MINISTERE_CULTURE_1')}
                        infosRight3={t('TITLE_DESCRIPTION_RIGHT_MINISTERE_CULTURE_2')}
                    />

                    <LivemapIframe>
                        <iframe title="livemap"
                            width="100%"
                            height="100%"
                            src="https://livemap.getwemap.com/dom.html?emmid=19405&token=at5762afd38d6f01.85348805"
                            allowfullscreen
                            frameborder="0"></iframe>
                    </LivemapIframe>

                    <CardCustomerLayout>
                        <CardCustomer
                            url='https://icom.museum/fr/news/la-carte-interactive-de-la-journee-internationale-des-musees-2021-est-en-ligne/'
                            picturecard={data.icom.childImageSharp}
                            altPictureCard='Icom logo'
                            title={t('TITLE_ICOM')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_ICOM_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_ICOM_POINT'),
                                    infoView: t('TITLE_ICOM_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.napoleoncities.eu/'
                            picturecard={data.napoleon.childImageSharp}
                            altPictureCard='Fédération Européenne des Cités Napoléoniennes logo'
                            title={t('TITLE_FECN')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_FECN_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_FECN_POINT'),
                                    infoView: t('TITLE_FECN_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://rendezvousauxjardins.culture.gouv.fr/Programme'
                            picturecard={data.rdvj.childImageSharp}
                            altPictureCard='Rendez-vous aux jardins logo'
                            title={t('TITLE_RDVJ')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_RDVJ_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_RDVJ_POINT'),
                                    infoView: t('TITLE_RDVJ_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.partir-en-livre.fr/evenements/#/search@46.92682428756609,2.3469499999999925,4.802554052480337e'
                            picturecard={data.cnl.childImageSharp}
                            altPictureCard='Partir en livre logo'
                            title={t('TITLE_PEL')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_PEL_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_PEL_POINT'),
                                    infoView: t('TITLE_PEL_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://journeesdupatrimoine.culture.gouv.fr/Programme'
                            picturecard={data.jep.childImageSharp}
                            altPictureCard='Journée Européennes du patrimoine logo'
                            title={t('TITLE_JEP')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_JEP_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_JEP_POINT'),
                                    infoView: t('TITLE_JEP_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.maregionsud.fr/la-fete-des-terrasses'
                            picturecard={data.rs.childImageSharp}
                            altPictureCard='Fêtes des Terrasses logo'
                            title={t('TITLE_FDT')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_FDT_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_FDT_POINT'),
                                    infoView: t('TITLE_FDT_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://nuitdelalecture.culture.gouv.fr/'
                            picturecard={data.ndm.childImageSharp}
                            altPictureCard='Nuit de la lecture logo'
                            title={t('TITLE_NDL')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_NDL_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_NDL_POINT'),
                                    infoView: t('TITLE_NDL_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='http://www.fete-en-seine.fr/'
                            picturecard={data.fes.childImageSharp}
                            altPictureCard='Fête en Seine logo'
                            title={t('TITLE_FES')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_FES_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_FES_POINT'),
                                    infoView: t('TITLE_FES_VIEW')
                                }
                            ]}
                        />
                    </CardCustomerLayout>

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with computer of Wemap map'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/'
                    />

                </div>
            </Layout>
        </React.Fragment>
    );
};
IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  background: file(relativePath: {eq: "background-image/bg-site_web_game2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  icom: file(relativePath: {eq: "industries/events/card-horizontal-icom.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  napoleon: file(
    relativePath: {eq: "industries/events/card-horizontal-napoleon.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  jep: file(relativePath: {eq: "industries/events/card-horizontal-jep.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  cnl: file(relativePath: {eq: "industries/events/card-horizontal-cnl.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  ndm: file(relativePath: {eq: "industries/events/card-horizontal-ndm-2019.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  rdvj: file(
    relativePath: {eq: "industries/events/card-horizontal-rdvj-2019.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  fes: file(relativePath: {eq: "industries/events/fete-en-seine-2019.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  rs: file(relativePath: {eq: "industries/events/card-horizontal-rs.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  banner: file(relativePath: {eq: "industries/culture/banner-culture.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  hachette: file(relativePath: {eq: "industries/culture/hachette-carte.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 550, layout: FIXED)
    }
  }
  uniqueHeritages: file(
    relativePath: {eq: "industries/culture/wemap-unique-heritages.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, height: 470, layout: FIXED)
    }
  }
}
`;
